import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Image,
  Form,
  Button,
  Input,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./App.css";
import "semantic-ui-offline/semantic.min.css";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import ProfileCreate from "./ProfileCreate";
import CONFIG from "../config/config";

export const bloodOptions = [
  { key: "a", text: "A", value: "a" },
  { key: "b", text: "B", value: "b" },
  { key: "o", text: "O", value: "o" },
  { key: "ab", text: "AB", value: "ab" },
  { key: "unknown", text: "Unknown", value: "unknown" },
];

export const rhOptions = [
  { key: "positive", text: "Rh positive", value: "positive" },
  { key: "negative", text: "Rh negative", value: "negative" },
  { key: "unknown", text: "Unknown", value: "unknown" },
];


export const diseaseList = [
  "ความดันโลหิต",
  "เบาหวาน",
  "โรคหัวใจ",
  "โรคเกี่ยวกับสมอง",
  "โรคปอด",
  "Stroke",
  "อื่นๆ",
];
export const foodList = [
  "อาหารทะเล",
  "ถั่วลิสง",
  "ไข่",
  "นม",
  "แป้งสาลี",
  "อื่นๆ",
];
const ProfileWrap = (props) => {
  const isMounted = useRef(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [isHaveExtraProfile, setIsHaveExtraProfile] = useState(true);

  // const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState([]);
  // const [showSaveState, setShowSaveState] = useState(false);


  const [chatChannel ,setChatChannel] = useState([])

  const history = useHistory();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadProfile = async () => {
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken");
    setProfileLoading(true);
    const [profileData, error] = await props.controller.loadProfile(apiToken);
    if (!isMounted.current) {
      return;
    }
    loadChatChannel(profileData.user);
    setProfileLoading(false);
    console.log("loadProfile", profileData);
    setProfileData(profileData);

    if (profileData.extra?.length > 0) {
      let extraObject = JSON.parse(profileData.extra)
      console.log("extraObject", extraObject)
      if ("nationality" in extraObject && "pre_name" in extraObject && "gender" in extraObject) {
        setIsHaveExtraProfile(true)
      } else {
        setIsHaveExtraProfile(false)
      }
      // if ( "completeProfile" in extraObject && extraObject.completeProfile === true) {
      //   setIsHaveExtraProfile(true)
      // } else {
      //   setIsHaveExtraProfile(false)
      // }
    }
  };

  const updateProfile = async (profile) => {
    setProfileLoading(true);
    let params = {
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
    };
    let firstName = profile.firstname;
    let lastName = profile.lastname;
    let phone = profile.phone;

    console.log(" updateProfile", firstName, lastName, phone)
    console.log(" updateProfile profileData: ", profileData)

    if (
      firstName &&
      firstName !== (profileData ? profileData.first_name : "")
    ) {
      params.first_name = firstName;
    }
    if (lastName && lastName !== (profileData ? profileData.last_name : "")) {
      params.last_name = lastName;
    }
    if (phone && phone !== (profileData ? profileData.phone_no : "")) {
      params.phone_no = phone;
    }
    if (profile.birthday) {
      params.dob = profile.birthday;
    }

    if (profile.email) {
      params.email = profile.email;
    }

    if (CONFIG.ISHEALTH_PLATFORM_PROFILE) {
      params = { 
        ...params,
        ...(profile.gender && { gender: profile.gender}),
        ...(profile.personWeight && { personWeight: profile.personWeight}),
        ...(profile.personHeight && { personHeight: profile.personHeight}),
        ...(profile.address && { address: profile.address}),
        ...(profile.bloodType && { bloodType: profile.bloodType}),
        ...(profile.rhType && { rhType: profile.rhType}),
        ...(profile.congenital && { congenital: profile.congenital}),
        ...(profile.foodAllergy && { foodAllergy: profile.foodAllergy}),
        ...(profile.drugAllergy && { drugAllergy: profile.drugAllergy}),
      } 

      const [data, error] = await props.controller.patchMyProfile(params);
      if (!isMounted.current) {
        return;
      }
      setProfileLoading(false);
      return [data, error];

    } else {
      const [data, error] = await props.controller.updateProfile(params);
      if (!isMounted.current) {
        return;
      }
      setProfileLoading(false);
      return [data, error];
    }
  }

  const updateHospitalNumber = async (hospital) => {
    setProfileLoading(true);
    console.log(" updateHospitalNumber",hospital)
    const [response, error] =
      await props.controller.patchProxyPatientHasDivision({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        id: hospital.proxyId,
        hn: hospital.hospitalNumber,
      });
    if (!isMounted.current) {
      return;
    }
    setProfileLoading(false);
    return [response, error];
  };

  const loadChatChannel = async (userId) => {
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken")
    const [data, error, network] = await props.controller.getChatChannel(
      { apiToken: apiToken, userId: userId , isPatient: true });

    if (isMounted.current) {
      if (data){
        setChatChannel(data.items)

        // let options = chatChannelToDropdownOption(data.items)

      }
    }
  }

  const handleSave = async (profile, hospital = null) => {
    let err = [];
    setProfileLoading(true)
    let [res1, err1] = await updateProfile(profile);
    if (err1) {
      
      err.push(err1);
    }
    if (hospital) {
      let [res2, err2] = await updateHospitalNumber(hospital);
      if (err2) {
        err.push(err2);
      }
    }
    setProfileLoading(false)
    // setSaveError(err);
    // setShowSaveState(true);
    setTimeout(() => {
      if (isMounted.current) {
        // setShowSaveState(false);
        if (err.length === 0) {
          history.push("/");
        }
      }
    }, 500);
    // props.saveComplete()
  };

  useEffect(() => {
    // มันต้อง เลือกแล้วว่าจะเอา apiToken จากที่ไหน cookies หรือ props มีอย่างใดอย่างหนึ่งคืออะไร แบบนี้ งง ซิ cookies scope ของเวลา คือแค่ไหน มั่วจริงๆ
    if (props.controller && (props.apiToken || Cookies.get("apiToken"))) {
      loadProfile();
    }
  }, [props.controller, props.apiToken, Cookies.get("apiToken")]);


  /** Handle "Logout" button */
  const handleLogout = () => {
    if(window.FB){
      window.FB.logout()
    }
    props.onLogout();
  };


  
  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer active={profileLoading} inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>

        {/* {showSaveState ? (
        saveError.length > 0 ? (
          <div style={{ textAlign: "center", fontWeight: "bold", color: "red" }} >
            <br /> บันทึกไม่สำเร็จ
          </div>
        ) : (
          <div style={{ textAlign: "center", fontWeight: "bold", color: "green" }} >
            <br /> บันทึกสำเร็จ
          </div>
        )
      ) : null} */}

        {isHaveExtraProfile ? (
          <ProfileInfo
            profile={profileData}
            hideLogout={props.hideLogout}
            hideDeleteUser={props.hideDeleteUser}
            noApiToken={props.noApiToken}
            apiToken={props.apiToken}
            controller={props.controller}
            handleGoBack={props.handleGoBack}
            onSave={handleSave}
            chatChannelList={chatChannel}
            onLogout={handleLogout}
            openAllergyProfile={props.openAllergyProfile}
            onSetOpenAllergy={props.onSetOpenAllergy}
            onDeleteUser={props.onDeleteUser}
          />
        ) : (
          <ProfileCreate
            /// Maynotuse
            profile={profileData}
            hideLogout={props.hideLogout}
            noApiToken={props.noApiToken}
            apiToken={props.apiToken}
            controller={props.controller}
            handleGoBack={props.handleGoBack}
            onSave={handleSave}
            chatChannelList={chatChannel}
            onLogout={handleLogout}
            profileStepperIndex={props.profileStepperIndex}
            onChangeProfileStepper={props.onChangeProfileStepper}
            openAllergyProfile={props.openAllergyProfile}
            onSetOpenAllergy={props.onSetOpenAllergy}
          />
        )}
      </Dimmer.Dimmable>
    </>
  );
};

ProfileWrap.defaultProps = {
  controller: {},
  onLogout: () => {},
  noApiToken: () => {},
  hideLogout: false,
  // isCompleteProfile: false,
  apiToken: "",
  handleGoBack: () => {},
  profileStepperIndex: 0,
  onChangeProfileStepper: ()=>{},
  openAllergyProfile: false,
  onSetOpenAllergy: ()=>{},

};

export default ProfileWrap;
